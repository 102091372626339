import React, { Component } from 'react';
import { Link } from 'react-router-dom'

export default class Sidebar extends Component{
    
    render() {
        const {menuIshiden, logout} = this.props;
        let className = 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion';
        if (menuIshiden) {
            className += ' toggled'
        }
        return (
            <ul className={className} id="accordionSidebar">

                <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                    <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-laugh-wink"></i>
                    </div>
                    <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
                </Link>

                <hr className="sidebar-divider my-0" />

                <li className="nav-item active">
                    <Link className="nav-link" to="/dashboard">
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span>
                    </Link>
                </li>

                    
                <hr className="sidebar-divider"/>

                <div className="sidebar-heading">Addons</div>

                <li className="nav-item">
                    <Link className="nav-link" to="/create">
                        <i className="fas fa-fw fa-table"></i>
                        <span>Create Project</span>
                    </Link>
                </li>
  
                <hr className="sidebar-divider d-none d-md-block"/>

                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle" onClick={this.props.toggleMenu}></button>
                </div>

                <hr className="sidebar-divider d-md-none"/>

                <li className="nav-item d-md-none">
                    <Link className="nav-link" to="/login" onClick={logout}>
                        <i className="fas fa-fw fa-cog"></i>
                        <span>logout</span>
                    </Link>
                </li>
            </ul>
        )
    }
}