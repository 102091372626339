import React from 'react';

const Button = ({id ,delite, edit}) => {
    return (
        <div className="container-fluid">
            <div className="row justify-content-around">

                <div className="col-lg-3">
                    <div className="btn btn-warning btn-icon-split" onClick={() => edit(id)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-exclamation-triangle"></i>
                        </span>
                        <span className="text">Редактировать</span>
                    </div>
                </div>

                <div className="col-lg-3">
                    <div className="btn btn-danger btn-icon-split" onClick={() => delite(id)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-trash"></i>
                        </span>
                        <span className="text">Удалить</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Button;