import React from 'react';
import html from 'react-inner-html';



const CardContent = ({project}) => {
    const {projectName, text} = project;

    const toHtml = (content) => {
        return <div {...html(content)}/>
    }
    const content = toHtml(text);
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold text-primary">{projectName}</h6>
                        </div>
                        <div className="card-body">
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CardContent;