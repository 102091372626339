import React from 'react';
import {Redirect} from 'react-router-dom';
import LoginPanel from '../component/login-panel';

const LoginPage = (props) => {
    const {isLogin, login, error} = props;
    if(!isLogin) {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                        </div>
                                            <LoginPanel 
                                                isLogin={isLogin}
                                                login={login}
                                                error={error}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return <Redirect to="/"/>
    }
    
}

export default LoginPage;