import React, { Component } from 'react';
import Card from '../card';
import { withRouter } from 'react-router-dom';

class CardList extends Component {
        
    selectProject = (id) => {
        const history = this.props.history
        history.push(`/dashboard/${id}`);
    }

    render() {
        const {keys} = this.props;
        const elements = keys.map((item) => {
            const {id} = item;
            return (
                <div key={id} className="col-xl-3 col-md-6 mb-4">
                    <Card {...item} selectProject={this.selectProject}/>
                </div>
                
            )
        })
        return(
            <div className="container-fluid">

                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                </div>
                <div className="row">

                    {elements}

                </div>
            </div>
        )
        
    }
}
export default withRouter(CardList);