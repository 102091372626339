import React from 'react';
const SuccessAlert = ({text}) =>{
    return (
        <div className="card bg-success text-white shadow mb-4">
            <div className="card-body">
                {text}
            </div>
        </div>
    )
}
export default SuccessAlert;