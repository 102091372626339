import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';

import Auth from '../services/auth-servises';

import Navbar from '../component/navbar';
import Sidebar from '../component/sidebar';
import CardList from '../component/card-list';
import Spiner from '../component/spiner';

import {DangerAlert} from '../component/alert-components'

export default class DashboardPage extends Component {
    state = {
        loading: true,
        keys: [],
        term: '',
        errorMesage: ''
    }
    ConectDB = new Auth();
    
    componentDidMount() {
        this.ConectDB.getAllKey()
            .then(keys => {
                this.setState({keys, loading: false})
            })
            .catch(err => {
                const error = this.ConectDB.handlError(err)
                this.setState({ errorMesage: error, success: '' })
            })
    }

    searchKey(keys, term) {
        if(term.length === 0) {
            return keys
        }
        return keys.filter((item) => {
            const name = item.projectName.toLowerCase();
			return name.startsWith(term.toLowerCase());
        })

    }

    onUpdateSearch = (term) => {
        this.setState({term})
    }

    render() {
        const {isLogin, toggleMenu, menuIshiden, logout } = this.props;
        const {loading, keys, term} = this.state; 

        const error = this.state.errorMesage ? <DangerAlert text={this.state.errorMesage}/> : '';

        const visiblePosts = this.searchKey(keys, term);

        const content = loading ? <Spiner /> : <CardList keys={visiblePosts}/>; 

        if(isLogin) {
            return (
                <div id="wrapper">
                    <Sidebar menuIshiden={menuIshiden} toggleMenu={toggleMenu} logout={logout}/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <Navbar toggleMenu={toggleMenu} logout={logout} onUpdateSearch={this.onUpdateSearch}/>
                        {error}
                        {content}
                    </div>
                    
                </div>
            )
        } else {
            return <Redirect to="/login"/>
        } 
    }
}
