import React, { Component } from 'react';


export default class Navbar extends Component {
    state = {
        term: ''
    }

    inputChange = (e) => {
        const term = e.target.value
        this.setState({term})
        this.props.onUpdateSearch(term)
    }

    render() {
        const {logout, onUpdateSearch} = this.props;
        const searchPanelEl = (
            <form className="d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" onChange={this.inputChange}/>
                <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                        <i className="fas fa-search fa-sm"></i>
                    </button>
                </div>
                </div>
            </form>
        )

        const searchPanel = onUpdateSearch ? searchPanelEl : '';

        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    
                <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3" onClick={this.props.toggleMenu}>
                    <i className="fa fa-bars"></i>
                </button>
    
                {searchPanel}
    
                <ul className="navbar-nav ml-auto">
                    {/* <!-- Nav Item - User Information --> */}
                    <li className="nav-item dropdown no-arrow">
                        <div className="nav-link dropdown-toggle" role="button">
                            <span className="mr-2 d-none d-lg-inline text-gray-600 small" onClick={logout}>Logout</span>
                        </div>
                    </li>
                </ul>
    
            </nav>
        )
    }
}
