import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

//pages
import { LoginPage, DashboardPage, CreateKeyPage, ProjectPaje, EditPage } from '../../pages'

import Auth from '../../services/auth-servises';


export default class App extends Component{
    state = {
        isLogin: false,
        error: '',
        menuIshiden: false
    }
    ConectDB = new Auth();


    login = (value) => {
        this.ConectDB.login(value.date)
            .then(data => {
                if(data[0] === false) {
                    this.setState({isLogin: false, error: data[1]})
                } else {
                    this.setState({isLogin: true, error: ''});
                }
            })
    }
    logout = () => {
        this.ConectDB.logout();
        this.setState({isLogin: false});
    }
    
    toggleMenu = () => {
        this.setState(({menuIshiden}) => ({menuIshiden: !menuIshiden}))
    }

    render() {
        const {isLogin, error, menuIshiden} = this.state;
        
        return (
            <Router>
                <Route path="/" exact render={() => {
                    return (
                        <DashboardPage 
                            isLogin={isLogin} 
                            menuIshiden={menuIshiden}
                            logout={this.logout}
                            toggleMenu={this.toggleMenu}
                            />
                    )
                    }} />
                <Route path="/dashboard/" exact render={() => {
                    return (
                        <DashboardPage 
                            isLogin={isLogin} 
                            menuIshiden={menuIshiden}
                            logout={this.logout}
                            toggleMenu={this.toggleMenu}
                            />
                    )
                    }}/>

                <Route path="/dashboard/:id" render={({match}) => {
                    const {id} = match.params;
                    return (
                        <ProjectPaje 
                            id={id}
                            isLogin={isLogin} 
                            menuIshiden={menuIshiden}
                            logout={this.logout}
                            toggleMenu={this.toggleMenu}
                            />
                    )
                    }} />    


                <Route path="/login" render={() => {
                    return (
                        <LoginPage 
                            isLogin={isLogin}
                            error={error}
                            login={this.login}
                        />
                    )
                }}/>
                <Route path="/create" render={() => {
                    return (
                        <CreateKeyPage
                            isLogin={isLogin}
                            menuIshiden={menuIshiden} 
                            toggleMenu={this.toggleMenu} 
                            logout={this.logout}
                        />
                    )
                }}/>
                <Route path="/edit/:id" exact render={({match}) => {
                    const {id} = match.params;
                    return <EditPage
                            id={id}
                            isLogin={isLogin}
                            menuIshiden={menuIshiden} 
                            toggleMenu={this.toggleMenu} 
                            logout={this.logout}
                    />
                }}

                />
            </Router>
        )
    }
}


