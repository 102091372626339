import React, { Component } from 'react';
import {Redirect, withRouter} from 'react-router-dom';

import Auth from '../services/auth-servises';

import Navbar from '../component/navbar';
import Sidebar from '../component/sidebar';
import Spiner from '../component/spiner';

import {DangerAlert, SuccessAlert} from '../component/alert-components'
import CardContent from '../component/card-content'
import Button from '../component/button-components'

class ProjectPaje extends Component {
    state = {
        loading: true,
        project: {},
        term: '',
        errorMesage: ''
    }
    ConectDB = new Auth();
    
    componentDidMount() {
        this.ConectDB.getElement(this.props.id)
            .then(keys => {
                const {projectName, text} = keys.data;
                this.setState({
                    project: {projectName, text}, 
                    loading: false})
            })
            .catch(err => {
                this.setState({ errorMesage: 'Что-то пошло не так', success: '' })
            })
    }

    delite = (id) => {
        this.ConectDB.delite(id)
            .then(data => {
                const status = data.request.status;
                if(status === 200) this.setState({
                    success: 'Проект удалён',
                    errorMesage: ''
                })
            })
            .catch(err => {
                const error = this.ConectDB.handlError(err)
                this.setState({ errorMesage: error, success: '' })
            })
    }
    edit = (id) => {
        const history = this.props.history
        history.push(`/edit/${id}`);
    }

    render() {
        const {isLogin, toggleMenu, menuIshiden, logout, id} = this.props;
        const {loading} = this.state; 

        const error = this.state.errorMesage ? <DangerAlert text={this.state.errorMesage}/> : null;
        const success = this.state.success ? <SuccessAlert text={this.state.success}/> : null; 

        let content;
        if(!loading && success) {
            content = null
        } else if(loading) content = <Spiner /> 
        else content = <CardContent project={this.state.project}/>

        if(isLogin) {
            return (
                <div id="wrapper">
                    <Sidebar menuIshiden={menuIshiden} toggleMenu={toggleMenu} logout={logout}/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <Navbar toggleMenu={toggleMenu} logout={logout}/>
                        {error}
                        {success}
                        {content}
                        <Button id={id} delite={this.delite} edit={this.edit}/> 
                    </div>
                    
                </div>
            )
        } else {
            return <Redirect to="/login"/>
        } 
    }
}
export default withRouter(ProjectPaje);