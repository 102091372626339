import axios from 'axios'
export default class Auth {
    constructor() {
        this._apiBase = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=';
        this._key = 'AIzaSyBgXJhuOzg6o_O0jz2T3lVjuMgPi4F3Gfs';

        this._apiUrl = 'https://test-db-13a98.firebaseio.com/'
        this._apiUrlKeys = 'https://test-db-13a98.firebaseio.com/keys.json'
        this._apiUrlId = 'https://test-db-13a98.firebaseio.com/keys/'
    }
    async getResource(opt) {
        const res = await axios.post(`${this._apiBase}${this._key}`, opt);

        if (res.status !== 200) {
            throw new Error(`Could not fetch, received ${res.status}`);
        }
        return res;
    }
    async postResource(opt) {
        const token = this.getToken();
        const res = await axios.post(`${this._apiUrlKeys}?auth=${token}`, opt);

        if (res.status !== 200) {
            throw new Error(`Could not fetch, received ${res.status}`);
        }
        return res;
    }
    async delFetch(url, end) {
        const token = this.getToken();
        const res = await axios.delete(`${url}${end}?auth=${token}`);
        
        if (res.status !== 200) {
            throw new Error(`Could not fetch, received ${res.status}`);
        }
        return res;
    }
    async PATCHFetch(url, end, data) {
        const token = this.getToken();
        const res = await axios.patch(`${url}${end}?auth=${token}`, data)
        if (res.status !== 200) {
            throw new Error(`Could not fetch, received ${res.status}`);
        }
        return res;
    }
    async edit(id, data) {
        const res = await this.PATCHFetch(this._apiUrl, `keys/${id}.json`, data);
        return res;
    }
    

    async getKeys(url, end) {
        const token = this.getToken();
        const res = await axios.get(`${url}${end}?auth=${token}`);

        if (res.status !== 200) {
            throw new Error(`Could not fetch, received ${res.status}`);
        }
        return res;
    }

    async getElement(id) {
        const res = await this.getKeys(this._apiUrl, `keys/${id}.json`);
        return res;
    }

    async getAllKey() {
        const res = await this.getKeys(this._apiUrl, 'keys.json');
        return this._transformKeys(res.data);
    }

    _transformKeys(obj) {
        const mass = [];
        for (const key in obj) {
            const el = {
                id: key,
                projectName: obj[key].projectName,
                text: obj[key].text
            } 
            mass.push(el)
        }
        return mass
    }


    async login(opt) {
        let returnAnswer = null;
        const data = opt;
        data.returnSecureToken = true;
        await this.getResource(data)
            .then(data => this.setToken(data.data))
            .then(data => returnAnswer = data)
            .catch(err => {
                const error = this.handlError(err)
                returnAnswer = [false, error]
            })
        return returnAnswer
    }
    logout() {
        return this.setToken();
    }
    async delite(id) {
        const res = await this.delFetch(this._apiUrl, `keys/${id}.json`);
        console.log('delElement: ', res);
        return res;
    }

    getToken() {
        const expData = new Date(localStorage.getItem('fb-token-exp'))
        if (new Date() > expData) {
            this.logout()
            return null
        }
        return localStorage.getItem('fb-token')
    }
    async setToken(response) {
        if (response) {
            const expDate = new Date(new Date().getTime() + +response.expiresIn * 1000)
            localStorage.setItem('fb-token', response.idToken)
            localStorage.setItem('fb-token-exp', expDate.toString())
            return await true
        } else {
            localStorage.clear()
            return false
        }
    }
    handlError(err) {
        let error = null;

        const errors = JSON.parse(err.request.response);
        const {message} = errors.error;
        if(message) {
            switch (message) {
                case 'INVALID_EMAIL':
                    error = 'Неверный email'
                    break
                case 'EMAIL_NOT_FOUND':
                    error = 'Данный email не найден';
                    break
                case 'INVALID_PASSWORD':
                    error = 'Неверный пароль';
                    break
                default:
                    error = 'Что-пошло не так'
            }
        } else{
            if(errors.error === 'Could not parse auth token.') {
                error = 'Время действия токена окончено, пожалуйста авторизируйтесь заново!'
            }
        }
        return error
    }
}
