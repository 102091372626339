import React from 'react';

const Card = ({id, projectName, selectProject}) => {

    return(
        
        <div className="card border-left-info shadow h-100 py-2" onClick={() => selectProject(id)}>
            <div className="card-body">
            <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                    <div className="h5 mb-0 font-weight-bold text-gray-800">{projectName}</div>
                </div>
                <div className="col-auto">
                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                </div>
            </div>
            </div>
        </div>

    )
}

export default Card;
