import React from 'react';
import './spiner.css'

const Spiner = () => {
    return (
        <div className="loadingio-spinner-spin-50w8icmv3s4">
            <div className="ldio-d2xhhm9iu07">
            <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
            </div>
        </div>
    )
}
export default Spiner;