import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Navbar from '../component/navbar';
import Sidebar from '../component/sidebar';

import {DangerAlert, SuccessAlert} from '../component/alert-components'

import Auth from '../services/auth-servises';

export default class CreateKeyPage extends Component {
    state = {
        projectName: '',
        text: ''
    }
    ConectDB = new Auth();
    modules = {
        toolbar: [
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image'],
          ['clean']
        ]
    }
    
    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    handleChange = (value) => {
        this.setState({ text: value})
    }
    inputChange = (e) => {
        this.setState({ projectName: e.target.value })
    }
    createProject = () => {
        const data = {
            projectName: this.state.projectName,
            text: this.state.text
        }
        if(this.state.projectName && this.state.text) {
            this.ConectDB.postResource(data)
                .then(data => {
                    const status = data.request.status;
                    if(status === 200) this.setState({
                        success: 'Проект создан',
                        projectName: '',
                        text: '',
                        errorMesage: ''
                    })

                })
                .catch(err => {
                    const error = this.ConectDB.handlError(err)
                    this.setState({ errorMesage: error, success: '' })
                })
        } else this.setState({ errorMesage: 'Поля не могут быть пустыми!' })
        
    }
    
    render() {
        const {isLogin, toggleMenu, menuIshiden, logout } = this.props;
        
        const error = this.state.errorMesage ? <DangerAlert text={this.state.errorMesage}/> : '';
        const success = this.state.success ? <SuccessAlert text={this.state.success}/> : '';
        
        if(isLogin) {
            return (
                <div id="wrapper">
                    <Sidebar menuIshiden={menuIshiden} toggleMenu={toggleMenu}/>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
    
                            <Navbar toggleMenu={toggleMenu} logout={logout}/>
    
                            <div className="container-fluid">
                                {error}
                                {success}
                                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    
                                    <h1 className="h3 mb-0 text-gray-800">Create project</h1>
                                    <div className="btn btn-success btn-icon-split" onClick={this.createProject}>
                                        <span className="icon text-white-50">
                                        <i className="fas fa-check"></i>
                                        </span>
                                        <span className="text">Create</span>
                                    </div>
                                </div>
    
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control form-control-user" 
                                        name="projectName"
                                        onChange={this.inputChange} 
                                        value={this.state.projectName} 
                                        placeholder="Enter Project name..." 
                                        />
                                </div>
    
                                <ReactQuill 
                                    value={this.state.text}
                                    onChange={this.handleChange}
                                    modules={this.modules}
                                    formats={this.formats} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            
            )
        } else {
            return <Redirect to="/login"/>
        } 

    }
}

