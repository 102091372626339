import React, { Component } from 'react';

export default class LoginPanel extends Component {
    state = {
        date: {
            email: '',
			password: ''
        }
    }
    
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState(() => {
            return {
                date: {
                    email: name === 'email' ? value : this.state.date.email,
                    password: name === 'password' ? value : this.state.date.password
                }
            }
        })
    }
    onSubmit = (e) => {
        e.preventDefault();
        const {login} = this.props;
        login(this.state)
        this.setState(() => {
            return {
                date: {
                    email: '',
                    password: ''
                }
            }
        })
    }

    render() {
        const errorMesage = (
            <>
                <hr/>
                    <div>{this.props.error}</div>
                <hr/>
            </>
        )
        const error = this.props.error ? errorMesage: ' ';
        return (
            <form className="user" onSubmit={this.onSubmit}>
                {error}
                <div className="form-group">
                    <input 
                        type="email" 
                        className="form-control form-control-user" 
                        id="exampleInputEmail"
                        name="email" 
                        aria-describedby="emailHelp" 
                        placeholder="Enter Email Address..." 
                        onChange={this.handleInputChange}
                        value={this.state.date.email}
                        />
                </div>
                <div className="form-group">
                    <input 
                        type="password" 
                        className="form-control form-control-user" 
                        id="exampleInputPassword" 
                        placeholder="Password"
                        name="password" 
                        onChange={this.handleInputChange}  
                        value={this.state.date.password}
                        />
                </div>
                    <button type="submit" className="btn btn-primary btn-user btn-block">Login</button>
            </form>
        )
    }
}

